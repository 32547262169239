import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import confetti from 'canvas-confetti';

const PromptForm = ({ prompt, onClose, setSelectedPrompt }) => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let customizedPrompt = prompt.description;
    prompt.variables.forEach(variable => {
      const value = formData[variable] || `{${variable}}`;
      customizedPrompt = customizedPrompt.replace(`{${variable}}`, value);
    });
    console.log('Customized Prompt:', customizedPrompt);
    setSelectedPrompt(customizedPrompt);
    fireConfetti();
    navigate('/');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-indigo-900 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md m-4 relative">
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 text-indigo-600 hover:text-indigo-800"
          aria-label="Close"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h3 className="text-2xl font-bold text-indigo-800 mb-6">{prompt.title}</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          {prompt.variables.map(variable => (
            <div key={variable}>
              <label className="block text-sm font-medium text-indigo-700 mb-1" htmlFor={variable}>
                {variable.charAt(0).toUpperCase() + variable.slice(1).replace(/_/g, ' ')}
              </label>
              <input
                type="text"
                id={variable}
                name={variable}
                onChange={handleInputChange}
                className="w-full p-2 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-indigo-50"
                placeholder={`Enter ${variable.replace(/_/g, ' ')}`}
              />
            </div>
          ))}
          <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="w-full sm:w-auto px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 rounded-md hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full sm:w-auto px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
            >
              Use Prompt
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PromptForm;
