import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, Navigate, useNavigate } from 'react-router-dom';
import Auth from './components/Auth';
import Write from './components/Write';
import Prompts from './components/Prompts';
import Emails from './components/Emails';
import Settings from './components/Settings';
import { Button } from './components/ui/button';
import SubscriptionModal from './components/SubscriptionModal';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import LandingPage from './components/LandingPage';
import axios from 'axios';

const NavItem = ({ to, label }) => (
  <li>
    <NavLink to={to} className={({ isActive }) => 
      `px-3 py-2 rounded-md text-sm font-medium ${isActive ? 'bg-indigo-200 text-indigo-900' : 'text-indigo-700 hover:bg-indigo-100'}`
    }>{label}</NavLink>
  </li>
);

const MobileNavItem = ({ to, label, onClick }) => (
  <NavLink to={to} className={({ isActive }) => 
    `block px-3 py-2 rounded-md text-base font-medium ${isActive ? 'bg-indigo-200 text-indigo-900' : 'text-indigo-700 hover:bg-indigo-100'}`
  } onClick={onClick}>{label}</NavLink>
);

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const App = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [userSettings, setUserSettings] = useState({
    name: '',
    role: '',
    company: '',
    writingStyle: 'professional',
    preferredLanguage: 'English',
    instagramHandle: '',
    youtubeChannel: '',
    tiktokHandle: '',
    websiteUrl: '',
    bio: '',
    signature: '',
    isSubscribed: false,
    isSubscriptionActive: false
  });
  
  useEffect(() => {
    const fetchUserSettings = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/api/user-settings`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          console.log(response.data)
          setUserSettings(response.data);
        } catch (error) {
          console.error('Error fetching user settings:', error);
        }
      }
    };
    fetchUserSettings();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      checkSubscription();
    }
  }, []);

  const checkSubscription = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, user is not authenticated');
      setIsAuthenticated(false);
      return;
    }
    
    try {
      const response = await axios.get(`${API_URL}/api/subscription-status`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Subscription status response:', response.data);
      setIsSubscribed(response.data.isSubscribed);
      setIsSubscriptionActive(response.data.isSubscriptionActive);
      setUserSettings(prevSettings => ({
        ...prevSettings,
        isSubscribed: response.data.isSubscribed,
        isSubscriptionActive: response.data.isSubscriptionActive
      }));
      setShowSubscriptionModal(!response.data.isSubscribed);
    } catch (error) {
      console.error('Subscription check error:', error);
      if (error.response && error.response.status === 401) {
        console.log('Token expired or invalid, logging out user');
        setIsAuthenticated(false);
        localStorage.removeItem('token');
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setIsSubscribed(false);
    setShowSubscriptionModal(false);
  };

  const handleAuthSuccess = () => {
    setIsAuthenticated(true);
    checkSubscription();
  };

  return (
    <Router>
      {!isAuthenticated ? (
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/auth" element={<Auth setIsAuthenticated={handleAuthSuccess} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
      <div className="min-h-screen flex flex-col bg-gradient-to-r from-pink-100 to-purple-100">
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center py-4">
              <h1 className="text-2xl font-bold text-indigo-800">InboxAI</h1>
              <div className="md:hidden">
                <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="text-indigo-700 hover:bg-indigo-100 p-2 rounded-md">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
              <nav className="hidden md:block">
                <ul className="flex space-x-4">
                  <NavItem to="/write" label="Write" />
                  <NavItem to="/prompts" label="Prompts" />
                  <NavItem to="/emails" label="My Emails" />
                  <NavItem to="/settings" label="Settings" />
                </ul>
              </nav>
              <div className="hidden md:block">
                <Button onClick={handleLogout} variant="outline" className="text-indigo-600 border-indigo-600 hover:bg-indigo-50">Logout</Button>
              </div>
            </div>
          </div>
          {mobileMenuOpen && (
            <div className="md:hidden">
              <nav className="px-2 pt-2 pb-3 space-y-1 bg-white">
                <MobileNavItem to="/write" label="Write" onClick={() => setMobileMenuOpen(false)} />
                <MobileNavItem to="/prompts" label="Prompts" onClick={() => setMobileMenuOpen(false)} />
                <MobileNavItem to="/emails" label="My Emails" onClick={() => setMobileMenuOpen(false)} />
                <MobileNavItem to="/settings" label="Settings" onClick={() => setMobileMenuOpen(false)} />
                <div className="pt-2">
                  <Button onClick={() => { handleLogout(); setMobileMenuOpen(false); }} variant="outline" className="w-full text-indigo-600 border-indigo-600 hover:bg-indigo-50">Logout</Button>
                </div>
              </nav>
            </div>
          )}
        </header>
        <main className="flex-grow container mx-auto px-4 py-8">
        <Routes>
          <Route path="/" element={<Navigate to="/write" replace />} />
          <Route path="/write" element={<Write userSettings={userSettings} isSubscribed={isSubscribed} setShowSubscriptionModal={setShowSubscriptionModal} selectedPrompt={selectedPrompt} setSelectedPrompt={setSelectedPrompt} />} />
          <Route path="/prompts" element={<Prompts setSelectedPrompt={setSelectedPrompt} />} />
          <Route path="/subscription-success" element={<SubscriptionSuccess onSubscriptionUpdate={checkSubscription} />} />
          <Route path="/subscription-cancelled" element={<Navigate to="/write" replace />} />
          <Route path="/emails" element={<Emails />} />
          <Route path="/settings" element={<Settings 
            userSettings={userSettings} 
            setUserSettings={setUserSettings} 
            isSubscribed={isSubscribed}
            isSubscriptionActive={isSubscriptionActive}
          />} />
          <Route path="*" element={<Navigate to="/write" replace />} />
        </Routes>
        </main>
        
        <footer className="bg-white border-t border-indigo-200">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <p className="text-center text-sm text-indigo-500">&copy; 2024 InboxAI. All rights reserved.</p>
          </div>
        </footer>
        {showSubscriptionModal && (
          <SubscriptionModal
            onClose={() => setShowSubscriptionModal(false)}
            onSubscriptionUpdate={checkSubscription}
          />
        )}
      </div>
      )}
    </Router>
  );
};

export default App;
