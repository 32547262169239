import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import EmailList from './EmailList';
import axios from 'axios';
import confetti from 'canvas-confetti';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

const Emails = () => {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/emails`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setEmails(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch emails. Please try again.');
      console.error('Error fetching emails:', err);
    }
    setIsLoading(false);
  };

  const handleDeleteEmail = async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/api/emails/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      if (response.status === 200) {
        setEmails(emails.filter((email) => email._id !== id));
        fireConfetti();
      } else {
        throw new Error('Failed to delete email');
      }
    } catch (err) {
      console.error('Error deleting email:', err);
      console.error('Response:', err.response?.data);
      alert('Failed to delete email. Please try again.');
    }
  };

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  if (isLoading) return (
    <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
    </div>
  );

  if (error) return (
    <div className="text-center py-8 text-red-500">{error}</div>
  );

  return (
    <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-pink-100 to-purple-100 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-indigo-800 mb-6">My Emails</h2>
        {emails.length === 0 ? (
          <div className="text-center py-12 bg-indigo-50 rounded-lg border border-indigo-200">
            <p className="text-indigo-600 mb-4">You haven't generated any emails yet.</p>
            <Button 
              onClick={() => {
                window.location.href = '/';
                fireConfetti();
              }} 
              className="bg-indigo-600 text-white hover:bg-indigo-700 transition-colors"
            >
              Start Writing
            </Button>
          </div>
        ) : (
          <EmailList emails={emails} onDelete={handleDeleteEmail} />
        )}
      </div>
    </div>
  );
};

export default Emails;
