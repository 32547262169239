import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import confetti from 'canvas-confetti';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

const Write = ({ userSettings, isSubscribed, setShowSubscriptionModal, selectedPrompt, setSelectedPrompt }) => {
  const [mode, setMode] = useState('Write');
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [textType, setTextType] = useState('E-Mail');
  const [textLength, setTextLength] = useState('Long');
  const [writingTone, setWritingTone] = useState('Friendly');
  const [copyConfirmation, setCopyConfirmation] = useState(false);

  useEffect(() => {
    if (selectedPrompt) {
      setInput(selectedPrompt);
      setMode('Write');
      setSelectedPrompt(null);
    }
  }, [selectedPrompt, setSelectedPrompt]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setInput('');
    setOutput('');
  };

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isSubscribed) {
      setShowSubscriptionModal(true);
      return;
    }
    setIsLoading(true);
    setOutput('');
    try {
      let message, context;
      switch (mode) {
        case 'Write':
          message = `Write an ${textType.toLowerCase()} about: ${input}. The length should be ${textLength.toLowerCase()} and the tone should be ${writingTone.toLowerCase()}.`;
          context = 'write-email';
          break;
        case 'Reply':
          message = `Generate a reply to this ${textType.toLowerCase()}: ${input}. The length should be ${textLength.toLowerCase()} and the tone should be ${writingTone.toLowerCase()}.`;
          context = 'email-reply';
          break;
        case 'Grammar Check':
          message = `Check and correct the grammar in this text: ${input}. Reply with a version that has perfect grammar.`;
          context = 'grammar-check';
          break;
        default:
          message = `Write an ${textType.toLowerCase()} about: ${input}. The length should be ${textLength.toLowerCase()} and the tone should be ${writingTone.toLowerCase()}.`;
          context = 'write-email';
      }

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.post(`${API_URL}/api/claude`, {
        message,
        context,
        userSettings,
        textPreferences: {
          textType,
          textLength,
          writingTone
        }
      }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data && response.data.reply) {
        setOutput(response.data.reply);
        await saveEmail(input.split('\n')[0], response.data.reply);
        fireConfetti();
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error:', error);
      setOutput(error.response?.data?.error || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const saveEmail = async (subject, content) => {
    try {
      const response = await axios.post(`${API_URL}/api/emails`, {
        subject: subject || 'Untitled',
        content
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      if (response.status === 201) {
        console.log('Email saved successfully');
      } else {
        throw new Error('Failed to save email');
      }
    } catch (error) {
      console.error('Error saving email:', error);
      setOutput(prevOutput => prevOutput + '\n\nFailed to save email. Please try again.');
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopyConfirmation(true);
    setTimeout(() => setCopyConfirmation(false), 2000);
    fireConfetti();
  };

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-indigo-800 mb-6">InboxAI Writer</h2>
        <div className="flex flex-wrap gap-2 mb-6">
          {['Write', 'Reply', 'Grammar Check'].map((tab) => (
            <button
              key={tab}
              onClick={() => handleModeChange(tab)}
              className={`px-3 py-1 text-sm font-medium rounded-md transition-colors ${
                mode === tab
                  ? 'bg-indigo-600 text-white'
                  : 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            placeholder={mode === 'Write' ? "What's your email about?" : mode === 'Reply' ? "Paste an e-mail that you want to reply to..." : "Paste text to check grammar"}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="w-full h-32 p-2 text-sm border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-indigo-50"
          />
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div>
              <label htmlFor="textType" className="block text-xs font-medium text-indigo-700 mb-1">Text Type</label>
              <select
                id="textType"
                value={textType}
                onChange={(e) => setTextType(e.target.value)}
                className="w-full p-2 text-sm bg-indigo-50 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none"
              >
                <option value="E-Mail">E-Mail</option>
                <option value="Message">Message</option>
              </select>
            </div>
            <div>
              <label htmlFor="textLength" className="block text-xs font-medium text-indigo-700 mb-1">Text Length</label>
              <select
                id="textLength"
                value={textLength}
                onChange={(e) => setTextLength(e.target.value)}
                className="w-full p-2 text-sm bg-indigo-50 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none"
              >
                <option value="Short">Short</option>
                <option value="Medium">Medium</option>
                <option value="Long">Long</option>
              </select>
            </div>
            <div>
              <label htmlFor="writingTone" className="block text-xs font-medium text-indigo-700 mb-1">Writing Tone</label>
              <select
                id="writingTone"
                value={writingTone}
                onChange={(e) => setWritingTone(e.target.value)}
                className="w-full p-2 text-sm bg-indigo-50 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none"
              >
                {['Formal', 'Friendly', 'Brutal', 'Persuasive', 'Confident', 'Cautionary', 'Inspirational', 'Thoughtful', 'Joyful', 'Exciting', 'Grieved', 'Loving', 'Surprised', 'Informative', 'Expertive'].map((tone) => (
                  <option key={tone} value={tone}>{tone}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-xs text-indigo-600">{input.length}/4096 characters</span>
            <button
              type="submit"
              disabled={isLoading || input.length === 0}
              className="px-4 py-2 bg-indigo-600 text-white text-sm font-medium rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-300 transition-colors"
            >
              {isLoading ? 'Generating...' : 'Generate'}
            </button>
          </div>
        </form>
        {output && (
          <div className="mt-6">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-semibold text-indigo-800">Generated Output:</h3>
              <button
                onClick={() => copyToClipboard(output)}
                className="text-indigo-600 hover:text-indigo-800 flex items-center text-sm"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                </svg>
                {copyConfirmation ? 'Copied!' : 'Copy'}
              </button>
            </div>
            <textarea
              value={output}
              readOnly
              className="w-full h-64 p-2 text-sm border border-indigo-300 rounded-md bg-indigo-50"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Write;
