import React, { useState } from 'react';
import confetti from 'canvas-confetti';

const EmailList = ({ emails, onDelete }) => {
  const [copiedId, setCopiedId] = useState(null);

  const copyToClipboard = (text, id) => {
    navigator.clipboard.writeText(text);
    setCopiedId(id);
    setTimeout(() => setCopiedId(null), 2000);
    fireConfetti();
  };

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  return (
    <ul className="space-y-4">
      {emails.map((email) => (
        <li 
          key={email._id} 
          className="bg-white rounded-lg border border-indigo-200 overflow-hidden cursor-pointer hover:border-indigo-400 transition-colors shadow-sm hover:shadow-md"
        >
          <div className="p-4 sm:p-6">
            <h3 className="font-bold text-lg text-indigo-900 mb-2">{email.subject}</h3>
            <p className="text-indigo-600 mb-4">{email.content.substring(0, 150)}...</p>
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-2 sm:space-y-0">
              <span className="text-sm text-indigo-400">{new Date(email.createdAt).toLocaleString()}</span>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => copyToClipboard(email.content, email._id)}
                  className="text-indigo-600 hover:text-indigo-800 flex items-center transition-colors px-3 py-1 rounded-md hover:bg-indigo-100"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                  </svg>
                  {copiedId === email._id ? 'Copied!' : 'Copy'}
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(email._id);
                    fireConfetti();
                  }}
                  className="text-indigo-600 hover:text-red-600 flex items-center transition-colors px-3 py-1 rounded-md hover:bg-indigo-100"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default EmailList;
